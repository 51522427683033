<template>
  <div class="back_four">
    <img src="~@/assets/img/invite_friends/img_biaoti3.png" class="img_four" />
    <div class="four_div">
      <div class="four_wid" v-for="(item, index) in numList" :key="index">
        <img
          v-if="recommendInfo.num == 0 || recommendInfo.num < item.num"
          src="~@/assets/img/invite_friends/img_hongbao_dailing@2x.png"
          class="img_four"
          style="padding: 5px 10px"
        />
        <img
          v-if="recommendInfo.num == item.num"
          src="~@/assets/img/invite_friends/img_hongbao_dangqian@2x.png"
          class="img_four"
          style="padding: 5px 10px"
        />
        <img
          v-if="recommendInfo.num > item.num"
          src="~@/assets/img/invite_friends/img_hongbao_lingqu.png"
          class="img_four"
          style="padding: 5px 10px"
        />

        <div class="four_box">
          <div :class="item.box_top">
            <span class="box_span1" v-if="item.monery">{{ item.monery }}</span>
            <span class="box_span2">元</span>
          </div>
          <div :class="item.span_receive">邀请第{{ item.num }}人</div>
          <div class="four_top">
            <Button :class="item.four_btn">{{ item.status }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let numList = [
  {
    num: "1",
    monery: "2",
  },
  {
    num: "2",
    monery: "3",
  },
  {
    num: "3",
    monery: "5",
  },
  { num: "4", monery: "8" },
  {
    num: "5",
    monery: "10",
  },
  { num: "6", monery: "12" },
  { num: "7", monery: "14" },
  { num: "8", monery: "16" },
  { num: "9", monery: "20" },
  { num: "10", monery: "33" },
];
export default {
  props: ["recommendInfo"],
  data() {
    return {
      // numList: numList,
    };
  },
  computed: {
    numList() {
      return (
        numList &&
        numList.map((item) => {
          if (this.recommendInfo.num == 0 || this.recommendInfo.num < item.num) {
            item.box_top = "box_top1";
            item.span_receive = "span_receive";
            item.status = "待领取";
            item.four_btn = "four_btn1";
          } else if (this.recommendInfo.num == item.num) {
            item.box_top = "box_top2";
            item.span_receive = "span_receive1";
            item.status = "待领取";
            item.four_btn = "four_btn1";
          } else {
            item.box_top = "box_top";
            item.span_receive = "span_receive1";
            item.status = "已领取";
            item.four_btn = "four_btn";
          }
          return item;
        })
      );
    },
  },
};
</script>
